import React from "react";

import "../assets/styles/component/OurMission.css";

import missionsConfig from "../configs/missions.json";

import ShavesIcon from "../assets/images/text-svg/ShavesIcon.svg";
import HaircutsIcon from "../assets/images/text-svg/HaircutIcon.svg";
import ShopIcon from "../assets/images/text-svg/ShopIcon.svg";
import OutMissionTitle from "../assets/images/text-svg/OurMission.svg";

function OurMission() {
    const data = missionsConfig.data;

    return (
        <div className={"om-wrapper"}>
            <div className={"om-title-container"}>
                <img className={"om-title"} src={OutMissionTitle} alt={"our mission"}/>
                <div className={"om-subtitle"}>
                    {missionsConfig.subtitle}
                </div>
            </div>
            <div className={"om-bottom-content-container"}>
                <div className={"om-bottom-content-item-wrapper"}>
                    <div className={"om-bottom-content-item-container"}>
                        <img src={data[0].image} alt={"bg-image"}
                             className={"om-bottom-content-item-bg"}/>
                        <div className={"om-bottom-content-item"}>
                            <div className={"om-bottom-content-item-icon"}>
                                <img className={"shaves-icon"} src={ShavesIcon} alt={"shaves-icon"}/>
                            </div>
                            <div className={"om-bottom-content-item-value"}>
                                {data[0].value}
                            </div>
                            <div className={"om-bottom-content-item-name"}>
                                {data[0].name}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"om-bottom-content-item-wrapper"}>
                    <div className={"om-bottom-content-item-container"}>
                        <img src={data[1].image} alt={"bg-image"}
                             className={"om-bottom-content-item-bg"}/>
                        <div className={"om-bottom-content-item"}>
                            <div className={"om-bottom-content-item-icon"}>
                                <img className={"haircut-icon"} src={HaircutsIcon} alt={"haircut-icon"}/>
                            </div>
                            <div className={"om-bottom-content-item-value"}>
                                {data[1].value}
                            </div>
                            <div className={"om-bottom-content-item-name"}>
                                {data[1].name}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"om-bottom-content-item-wrapper"}>
                    <div className={"om-bottom-content-item-container"}>
                        <img src={data[2].image} alt={"bg-image"}
                             className={"om-bottom-content-item-bg"}/>
                        <div className={"om-bottom-content-item"}>
                            <div className={"om-bottom-content-item-icon"}>
                                <img className={"shop-icon"} src={ShopIcon} alt={"shop-icon"}/>
                            </div>
                            <div className={"om-bottom-content-item-value"}>
                                {data[2].value}
                            </div>
                            <div className={"om-bottom-content-item-name"}>
                                {data[2].name}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"om-line"}/>
        </div>
    )
}

export default OurMission;