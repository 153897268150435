import React, {useCallback, useEffect} from "react";

import "../../assets/styles/component/common/HorizontalScrollBar.css";
import {clamp, NumOrBigInt} from "../../utils/NumberUtils";

interface ScrollBarProps {
    divRef?: React.RefObject<HTMLDivElement>;
    thumbWidth: number;
    onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
    scrollContainer?: React.RefObject<HTMLDivElement>;
}

function HorizontalScrollBar(props: ScrollBarProps) {
    const thumbRef = React.useRef<HTMLDivElement>(null);
    const innerRef = React.useRef<HTMLDivElement>(null);

    const [isDragging, setIsDragging] = React.useState(false);
    const [startX, setStartX] = React.useState(0);
    const [startLeft, setStartLeft] = React.useState(0);

    const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        if (thumbRef.current) {
            setIsDragging(true);
            setStartX(e.clientX);
            setStartLeft(thumbRef.current.offsetLeft);
        }
    }, []);

    const handleMouseMove = useCallback((e: MouseEvent) => {
        if (isDragging && thumbRef.current && innerRef.current) {
            const maxLeft = innerRef.current.offsetWidth - thumbRef.current.offsetWidth;

            const deltaY = e.clientX - startX;

            const startRight = innerRef.current.offsetWidth - thumbRef.current.offsetWidth - startLeft;

            let newRight: NumOrBigInt = startRight - deltaY;

            newRight = clamp({value: newRight, limit1: 0, limit2: maxLeft}) as number;

            thumbRef.current.style.right = `${newRight}px`;
            if (props.onScroll) {
                props.onScroll({
                    currentTarget: {
                        scrollLeft: newRight * 10
                    } as HTMLDivElement
                } as React.UIEvent<HTMLDivElement>);
            }
            if (props.scrollContainer && props.scrollContainer.current) {
                const scrollRatio = newRight / maxLeft;
                props.scrollContainer.current.scrollLeft = -(scrollRatio) * (props.scrollContainer.current.scrollWidth - props.scrollContainer.current.clientWidth);
            }
        }
    }, [isDragging, startX, startLeft, props]);

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    useEffect(() => {
        if (isDragging) {
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        } else {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        }

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDragging, handleMouseMove, handleMouseUp]);

    useEffect(() => {
        // listen to scrollContainer scroll event
        if (props.scrollContainer && props.scrollContainer.current) {
            const scrollContainer = props.scrollContainer.current;
            const handleScroll = () => {
                if (thumbRef.current && innerRef.current) {
                    const maxLeft = innerRef.current.offsetWidth - thumbRef.current.offsetWidth;
                    const scrollRatio = scrollContainer.scrollLeft / (scrollContainer.scrollWidth - scrollContainer.clientWidth);
                    const newWidth = scrollRatio * maxLeft;
                    thumbRef.current.style.right = `${-newWidth}px`;
                }
            };
            scrollContainer.addEventListener("scroll", handleScroll);

            return () => {
                scrollContainer.removeEventListener("scroll", handleScroll);
            };
        }
    }, [props.scrollContainer]);

    return (
        <div className={"h-scrollbar"} ref={props.divRef}>
            <div className={"h-scrollbar-inner-container"} ref={innerRef}>
                <div className={"h-scrollbar-thumb"} style={{
                    width: props.thumbWidth
                }}
                     ref={thumbRef}
                     onMouseDown={handleMouseDown}
                />
            </div>
        </div>
    );
}

export default HorizontalScrollBar;