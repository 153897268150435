import React, {useEffect, useRef} from "react";

import "../assets/styles/parts/AppWelcome.css";

import headerConfigs from "../configs/header.json";

import Star from "../assets/images/text-svg/3Star.svg";
import YourChoice from "../assets/images/text-svg/YourChoice.svg";
import OurPerfection from "../assets/images/text-svg/OurPerfection.svg";
import BNButton from "../assets/images/text-svg/BnButton.svg";
import BNDownBtnInner from "../assets/images/text-svg/DownBtnInner.svg";
import ListIcon from "../assets/images/text-svg/ListIcon.svg";
import Dot from "../assets/images/text-svg/Dot.svg";
import {navigateTo} from "../utils/NavUtils";
import {openBookNowWeb} from "../services/BookNow";

function AppWelcome() {
    const wallPaper = headerConfigs.wallpaper;
    const description = headerConfigs.description;

    const ServiceId = "service";
    const AboutId = "aboutUs";
    const StoryId = "story";
    const BarbersId = "about";
    const ContactId = "contact";

    //check if this element is out of the viewport
    //if it is, then add a class to the element

    const AWrapperRef = useRef<HTMLDivElement>(null);

    const [floating, setFloating] = React.useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setFloating(false)
                } else {
                    setFloating(true)
                }
            },
            {threshold: 0}
        );

        const observee = AWrapperRef.current;

        if (observee) {
            observer.observe(observee);
        }

        return () => {
            if (observee) {
                observer.unobserve(observee);
            }
        };
    }, [AWrapperRef]);

    const HeaderRef = useRef<HTMLDivElement>(null);

    const [floatingHeader, setFloatingHeader] = React.useState(false);

    const [isMobile, setIsMobile] = React.useState(false);
    const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);

    useEffect(() => {
        const resize = () => {
            console.log(window.innerWidth);
            if (window.innerWidth < 768) {
                console.log("Mobile");
                setIsMobile(true);
            } else {
                console.log("Not mobile");
                setIsMobile(false);
            }
        }
        resize();
        window.addEventListener("resize", resize);
    }, [setIsMobile]);

    useEffect(() => {
        const observee = HeaderRef.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setFloatingHeader(false)
                    if (observee) {
                        observee.style.height = "fit-content";
                        if(isMobile) {
                            console.log("Down");
                            setFloating(false);
                        }else{
                        }
                    }
                } else {
                    setFloatingHeader(true)
                    if (observee) {
                        if (isMobile) {
                            console.log("Up");
                            observee.style.height = "fit-content";
                            setFloating(true);
                        }
                        else{
                            observee.style.height = entry.target.children[0].clientHeight + "px";
                        }
                    }
                }
            },
            {threshold: 0}
        );
        if (observee) {
            observer.observe(observee);
        }

        return () => {
            if (observee) {
                observer.unobserve(observee);
            }
        };
    }, [HeaderRef, isMobile]);

    return (
        <div className={"aw-wrapper"} style={{backgroundImage: `url(${wallPaper})`}} id={"header"}>
            <div ref={HeaderRef} style={{width: "100vw"}}>
                <div className={"h-wrapper" + (floatingHeader && !isMobile ? " floatingNav" : "")}>
                    <div className={"h-container"}>
                        <div className={"h-logo-container"}>
                            <div className={"h-logo-one"}>
                                THIN
                            </div>
                            <div className={"h-logo-two"}>
                                Barbershop
                            </div>
                        </div>
                        <div className={"h-nav-box-container"}>
                            <div className={"h-nav-box"} onClick={() => {
                                navigateTo(ServiceId);
                            }}>
                                <div className={"h-nav-box-title"}>
                                    Services
                                </div>
                            </div>
                            <div className={"h-nav-box"} onClick={() => {
                                navigateTo(AboutId);
                            }}>
                                <div className={"h-nav-box-title"}>
                                    About Us
                                </div>
                            </div>
                            <div className={"h-nav-box"} onClick={() => {
                                navigateTo(BarbersId);
                            }}>
                                <div className={"h-nav-box-title"}>
                                    Barbers
                                </div>
                            </div>
                            <div className={"h-nav-box"} onClick={() => {
                                navigateTo(StoryId);
                            }}>
                                <div className={"h-nav-box-title"}>
                                    Story
                                </div>
                            </div>
                            <div className={"h-bn-button"} onClick={() => {
                                openBookNowWeb();
                            }}>
                                <div className={"h-bn-button-img"}>
                                    Book Now
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"w-wrapper"}>
                <div className={"w-three-star-wrapper"}>
                    <img src={Star} alt={"star"} style={{width: "100%", height: "100%"}}/>
                </div>
                <div className={"w-content"}>
                    <img className={"w-yc"} src={YourChoice} alt={"YourChoice"}/>
                    <div className={"w-c-inner"}>
                        <img className={"w-op"} src={OurPerfection} alt={"OurPerfection"}/>
                        <div className={"w-description"}>
                            {description}
                        </div>
                    </div>
                    <div className={"w-button"} onClick={() => {
                        openBookNowWeb();
                    }}>
                        <img className={"w-button-img"} src={BNButton} alt={"BNButton"}/>
                    </div>
                </div>
                <div ref={isMobile ? null : AWrapperRef} style={{width: "100%", display: 'flex', flexDirection: "column"}}>
                    <div className={"w-down-btn-wrapper" + (floating ? " floatingBtn" : "")}>
                        <div className={"w-down-btn"} onClick={() => {
                            if (floating) {
                                navigateTo("header");
                            } else {
                                navigateTo(ServiceId);
                            }
                        }}>
                            <img className={"w-down-btn-inner"} src={BNDownBtnInner} alt={"bn inner"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"mb-nav-btn" + (isMobileNavOpen ? " mb-nav-open" : "")}>
                <div className={"mb-nav-content"}>
                    <div className={"mb-nav-list"}>
                        <div className={"mb-nav-item"} onClick={() => {
                            navigateTo(ServiceId);
                            setIsMobileNavOpen(false);
                        }}>
                            <img className={"mb-nav-i-decor"} src={Dot} alt={"dot"}/>
                            Services
                        </div>
                        <div className={"mb-nav-item"} onClick={() => {
                            navigateTo(AboutId);
                            setIsMobileNavOpen(false);
                        }}>
                            <img className={"mb-nav-i-decor"} src={Dot} alt={"dot"}/>
                            About Us
                        </div>
                        <div className={"mb-nav-item"} onClick={() => {
                            navigateTo(BarbersId);
                            setIsMobileNavOpen(false);
                        }}>
                            <img className={"mb-nav-i-decor"} src={Dot} alt={"dot"}/>
                            Barbers
                        </div>
                        <div className={"mb-nav-item"} onClick={() => {
                            navigateTo(StoryId);
                            setIsMobileNavOpen(false);
                        }}>
                            <img className={"mb-nav-i-decor"} src={Dot} alt={"dot"}/>
                            Story
                        </div>
                        <div className={"mb-nav-item"} onClick={() => {
                            navigateTo(ContactId);
                            setIsMobileNavOpen(false);
                        }}>
                            <img className={"mb-nav-i-decor"} src={Dot} alt={"dot"}/>
                            Contact
                        </div>
                    </div>
                    <div className={"mb-nav-bn-button"} onClick={() => {
                        openBookNowWeb();
                    }}>
                        <img className={"mb-nav-bn-img"} src={BNButton} alt={"BNButton"}/>
                    </div>
                </div>
                <div className={"mb-nav-o-b-container"}
                     onClick={() => setIsMobileNavOpen((prevState) => !prevState)}>
                    <img className={"mb-nav-o-b-img"} src={ListIcon} alt={"list icon"}/>
                </div>
            </div>
            <div ref={isMobile ? AWrapperRef : null}>
                <div className={"mb-down-btn-wrapper" + (floating ? " floatingBtn" : "")}>
                    <div className={"mb-down-btn"} onClick={() => {
                        if (floating) {
                            navigateTo("header");
                        } else {
                            navigateTo(ServiceId);
                        }
                    }}>
                        <img className={"mb-down-btn-inner"} src={BNDownBtnInner} alt={"bn inner"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppWelcome;