export type NumOrBigInt = (bigint | number);

export type GenericNumber<T> = {
    value: T,
    limit1: T,
    limit2: T,
}

export type NumberClamp = GenericNumber<number>;
export type BigIntClamp = GenericNumber<bigint>;

export function clamp({value, limit1, limit2}: NumberClamp | BigIntClamp): NumOrBigInt {
    if ([value, limit1, limit2].some(Number.isNaN)) return NaN;

    if (limit1 > limit2) {
        [limit1, limit2] = [limit2, limit1];
    }
    const min = (value1: NumOrBigInt, value2: NumOrBigInt): NumOrBigInt => {
        return value1 < value2 ? value1 : value2;
    }
    const max = (value1: NumOrBigInt, value2: NumOrBigInt): NumOrBigInt => {
        return value1 > value2 ? value1 : value2;
    }

    return min(max(value, limit1), limit2);
}