import React, {useCallback, useEffect, useRef, useState} from "react";

import "../assets/styles/component/BarberDisplay.css";

import aboutUsConfig from "../configs/aboutus.json";

interface BarberProps {
    name: string;
    description: string;
    image: string;
    onClick: () => void;
}

function BarberItem({name, description, image, onClick}: BarberProps) {


    return (
        <div className={"bd-item"} onClick={onClick}>
            <img src={image} alt={name} className={"bd-item-image"}/>
            <div className={"bd-item-detail"}>
                <div className={"bd-item-detail-bg"}/>
                <div className={"bd-item-name"}>
                    {name}
                </div>
                <div className={"bd-item-description"}>
                    {description}
                </div>
            </div>
        </div>
    );
}

function BarberDisplay() {
    const barberList = aboutUsConfig.barbers;

    const focusPointLowerBound = 0.1;
    const focusPointUpperBound = window.screen.width < 768 ? 0.5 : 0.15;

    const scroller = useRef<HTMLDivElement>(null);

    const [focusIndex, setFocusIndex] = useState(6);
    const [cooldown, setCooldown] = useState(false);
    const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout | null>(null);

    const scrollToItem = (index: number, s: HTMLDivElement, instant: boolean = false) => {
        if (s) {
            const items = s.getElementsByClassName("bd-item");
            const item = items[index % items.length];
            if (item) {
                const computedStyle = window.getComputedStyle(item);
                const itemWidth = item.getBoundingClientRect().width + parseFloat(computedStyle.marginLeft) + parseFloat(computedStyle.marginRight);
                const focusPointLeftBound = window.innerWidth * focusPointLowerBound;

                if (instant) {
                    s.scrollLeft = index * itemWidth - focusPointLeftBound;
                    return;
                }
                s.scrollTo({
                    left: index * itemWidth - focusPointLeftBound,
                    behavior: 'smooth'
                });
            }
        }
    };

    // const onWheel = useCallback((event: WheelEvent) => {
    //     event.stopPropagation();
    //     event.preventDefault();
    //
    //     if (cooldown) return;
    //
    //     setCooldown(true);
    //     setTimeout(() => setCooldown(false), 500); // 500ms cooldown
    //
    //     setFocusIndex((prevIndex) => {
    //         let newIndex = prevIndex + Math.sign(event.deltaY);
    //         if (scroller.current) {
    //             if (newIndex < barberList.length) {
    //                 scrollToItem(newIndex + barberList.length + 1, scroller.current, true);
    //                 newIndex = newIndex + barberList.length;
    //             } else if (newIndex >= barberList.length * 2) {
    //                 scrollToItem(newIndex - barberList.length - 1, scroller.current, true);
    //                 newIndex = newIndex - barberList.length
    //             }
    //             scrollToItem(newIndex, scroller.current);
    //         }
    //         return newIndex;
    //     });
    // }, [barberList.length, cooldown]);

    const onClick = useCallback((index: number) => {
        if (cooldown) return;

        setCooldown(true);
        setTimeout(() => setCooldown(false), 500); // 500ms cooldown

        if (index === focusIndex || index - barberList.length === focusIndex || index + barberList.length === focusIndex) return;

        setFocusIndex((prevIndex) => {
            let newIndex = index;
            if (scroller.current) {
                if (newIndex < barberList.length) {
                    scrollToItem(newIndex + barberList.length + 1, scroller.current, true);
                    newIndex = newIndex + barberList.length;
                } else if (newIndex >= barberList.length * 2) {
                    scrollToItem(newIndex - barberList.length - 1, scroller.current, true);
                    newIndex = newIndex - barberList.length
                }
                scrollToItem(newIndex, scroller.current);
            }
            return newIndex;
        });
    }, [barberList.length, cooldown, focusIndex]);

    // useEffect(() => {
    //     if (scroller.current) {
    //         const currentScroller = scroller.current;
    //         currentScroller.addEventListener("wheel", onWheel);
    //         return () => {
    //             currentScroller.removeEventListener("wheel", onWheel);
    //         };
    //     }
    // }, [onWheel]);

    useEffect(() => {
        if (scroller.current) {
            scrollToItem(focusIndex, scroller.current, false);
            window.addEventListener("resize", () => {
                scrollToItem(barberList.length, scroller.current!, false);
            });
        }
    }, [barberList.length, focusIndex]);



    const startScrollLoop = useCallback(() => {
        if (scrollInterval) return;
        const interval = setInterval(() => {
            setFocusIndex((prevIndex) => {
                let newIndex = prevIndex + 1;
                if (scroller.current) {
                    if (newIndex < barberList.length) {
                        scrollToItem(newIndex + barberList.length + 1, scroller.current, true);
                        newIndex = newIndex + barberList.length;
                    } else if (newIndex >= barberList.length * 2) {
                        scrollToItem(newIndex - barberList.length - 1, scroller.current, true);
                        newIndex = newIndex - barberList.length
                    }
                    scrollToItem(newIndex, scroller.current);
                }
                return newIndex;
            });
        }, aboutUsConfig.sliderSpeed); // Change item every 2 seconds
        setScrollInterval(interval);
    },[barberList.length, scrollInterval]);

    const stopScrollLoop = useCallback(() => {
        if (scrollInterval) {
            clearInterval(scrollInterval);
            setScrollInterval(null);
        }
    }, [scrollInterval]);

    const onScroll = useCallback(() => {
        if (scroller.current) {
            const items = scroller.current.getElementsByClassName("bd-item");

            const focusPointLeftBound = window.screen.width * (focusPointLowerBound-0.05);
            const focusPointRightBound = window.screen.width * (focusPointUpperBound+0.05);

            // Check all the items if they are in the focus point
            for (let i = 0; i < items.length; i++) {
                const item = items[i] as HTMLElement;
                const itemLeft = item.getBoundingClientRect().left;
                if (itemLeft < focusPointRightBound && focusPointLeftBound < itemLeft) {
                    item.classList.add("focused");
                } else {
                    item.classList.remove("focused");
                }
            }
        }
    }, [focusPointUpperBound]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        startScrollLoop();
                        console.log("Start");
                    }
                    else {
                        stopScrollLoop();
                        console.log("Stop");
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        const cur = scroller.current;
        
        if (cur) {
            observer.observe(cur);
        }

        return () => {
            if (cur) {
                observer.unobserve(cur);
                stopScrollLoop();
                console.log("Stop");
            }
        };
    }, [scroller, startScrollLoop, stopScrollLoop]);

    return (
        <div className={"bd-wrapper"} ref={scroller} onScroll={onScroll}>
            {barberList.map((barber, index) => {
                return (
                    <BarberItem key={index} name={barber.name} description={barber.description} image={barber.image}
                                onClick={() => onClick(index)}/>
                );
            })}
            {barberList.map((barber, index) => {
                return (
                    <BarberItem key={index} name={barber.name} description={barber.description} image={barber.image}
                                onClick={() => onClick(index + barberList.length)}/>
                );
            })}
            {barberList.map((barber, index) => {
                return (
                    <BarberItem key={index} name={barber.name} description={barber.description} image={barber.image}
                                onClick={() => onClick(index + barberList.length * 2)}/>
                );
            })}
        </div>
    );
}

export default BarberDisplay;