import React from "react";

import AboutUsTitle from "../assets/images/text-svg/AboutUsTitle.svg";

import "../assets/styles/parts/AboutUs.css";
import BarberDisplay from "../components/BarberDisplay";

function AboutUs() {
    return (
        <div className={"au-wrapper"} id={"about"}>
            <img src={AboutUsTitle} alt={"About Us"} className={"au-wrapper-title"}/>
            <div className={"au-content-wrapper"}>
                <BarberDisplay/>
            </div>
        </div>
    );
}

export default AboutUs;