
function navigateTo(elementId: string) {
    console.log("Navigating to: " + elementId);
    const element = document.getElementById(elementId);
    console.log("Element: " + element);
    if (element) {
        console.log("Element found: ", element);
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    } else {
        console.error("Element not found: ", elementId);
    }
}

export {navigateTo};