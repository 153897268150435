import React, {useCallback, useEffect, useRef, useState} from "react";

import OurStoryTitle from "../assets/images/text-svg/OurStory.svg";
// import ScrollBar from "../components/common/ScrollBar";

import "../assets/styles/parts/OurStory.css";

import storyConfig from "../configs/story.json";

function OurStory() {

    const itemListRef = useRef<HTMLDivElement>(null);
    const sliderWrapperRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<HTMLDivElement>(null);

    // const [thumbHeight, setThumbHeight] = useState(0);
    const [currentSelected, setCurrentSelected] = useState(0);
    const [fade, setFade] = useState(false);
    const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const resizeScrollBar = () => {
            if (window.screen.width < 768) return;
            if (itemListRef.current && sliderWrapperRef.current && sliderRef.current) {
                const itemListHeight = itemListRef.current.getBoundingClientRect();
                sliderWrapperRef.current.style.height = `${itemListHeight.height}px`;
            }
            if (sliderRef.current && itemListRef.current) {
                // const itemListHeight = itemListRef.current.getBoundingClientRect();
                // const sliderHeight = sliderRef.current.getBoundingClientRect();
                // const thumbHeight = (sliderHeight.height / itemListHeight.height) * sliderHeight.height;
                // setThumbHeight(thumbHeight);
            }
        }
        resizeScrollBar();
        window.addEventListener("resize", () => {
            resizeScrollBar();
        });
    }, []);

    const handleImageChange = (index: number) => {
        setFade(true);
        setTimeout(() => {
            setCurrentSelected(index);
            setFade(false);
        }, 500); // Duration of the fade-out transition
    };

    const startScrollLoop = useCallback(() => {
        if (scrollInterval) return;
        const interval = setInterval(() => {
            handleImageChange((currentSelected + 1) % storyConfig.images.length);
        }, storyConfig.sliderSpeed);
        setScrollInterval(interval);
    },[currentSelected, scrollInterval]);

    const stopScrollLoop = useCallback(() => {
        if (scrollInterval) {
            clearInterval(scrollInterval);
            setScrollInterval(null);
        }
    }, [scrollInterval]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        startScrollLoop();
                        console.log("Start");
                    }
                    else {
                        stopScrollLoop();
                        console.log("Stop");
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        observer.observe(window.document.getElementById("story") as HTMLElement);

        return () => {
            observer.unobserve(window.document.getElementById("story") as HTMLElement);
            stopScrollLoop();
            console.log("Stop");
        };
    }, [startScrollLoop, stopScrollLoop]);

    return (
        <div className={"os-wrapper"} id={"story"}>
            <div className={"os-title-container"}>
                <img className={"os-title"} src={OurStoryTitle} alt={"Our Story"}/>
            </div>
            <div className={"os-content-wrapper"}>
                <div className={`os-display ${fade ? "fade-out" : "fade-in"}`} style={{backgroundImage: `url(${storyConfig.images[currentSelected]})`}}>
                    <div className={"os-display-text-wrapper"}>
                        <div className={"os-display-text-container"}>
                            <div className={"os-display-text"}>
                                {storyConfig.stories[currentSelected]}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"os-img-slider"}>
                    <div className={"os-slider"} ref={sliderWrapperRef}>
                        <div className={"sb-wrapper"}>
                            {/* <ScrollBar divRef={sliderRef} thumbHeight={thumbHeight} scrollContainer={itemListRef}/> */}
                        </div>
                    </div>
                    <div className={"os-img-list"} ref={itemListRef}>
                        {getStory(handleImageChange)}
                    </div>
                </div>
            </div>
        </div>
    );
}

function getStory(setCurrentSelected: (index: number) => void) {
    return storyConfig.images.map((s, index) => {
        return (
            <img className={"os-img-item"}
                 src={s}
                 alt={"Our Story - " + index}
                 key={index}
                 onClick={() => setCurrentSelected(index)}
            />
        )
    });
}

export default OurStory;