import React, {useCallback, useEffect, useRef, useState} from "react";

import "../assets/styles/parts/OurServices.css";

import servicesConfig from "../configs/services.json";

import OurServicesTitle from "../assets/images/text-svg/OurServices.svg";
import TwoStar from "../assets/images/text-svg/2Star.svg";
import WorkingHour from "../components/WorkingHour";
import BNButton from "../assets/images/text-svg/BnButton.svg";
import HorizontalScrollBar from "../components/common/HorizontalScrollBar";
import OurMission from "../components/OurMission";
import {openBookNowWeb, openFacebook} from "../services/BookNow";

function OurServices() {
    const services = servicesConfig.services;

    const serviceList = useRef<HTMLDivElement>(null);
    const sliderWrapperRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<HTMLDivElement>(null);

    const [thumbWidth, setThumbWidth] = useState(0);

    const onWheel = useCallback((e: WheelEvent) => {
        console.log("Wheel");
        e.preventDefault();
        e.stopPropagation();
        if (serviceList.current) {
            serviceList.current.scrollBy(
                {
                    left: e.deltaY * 10,
                    behavior: "smooth"
                }
            );
        }
    }, []);

    useEffect(() => {
        if (serviceList.current) {
            const currentScroller = serviceList.current;
            currentScroller.addEventListener("wheel", onWheel);
            return () => {
                currentScroller.removeEventListener("wheel", onWheel);
            };
        }
    }, [onWheel]);

    useEffect(() => {
        const resizeScrollBar = () => {
            if (sliderRef.current && serviceList.current) {
                const itemListWidth = serviceList.current.getBoundingClientRect();
                const sliderWidth = sliderRef.current.getBoundingClientRect();
                const thumbWidth = (sliderWidth.width / itemListWidth.width) * sliderWidth.width;
                setThumbWidth(thumbWidth);
            }
        }
        resizeScrollBar();
        window.addEventListener("resize", () => {
            resizeScrollBar();
        });
    }, [sliderRef]);

    return (
        <div className={"oser-wrapper"} id={"service"}>
            <div className={"oser-top-container"}>
                <div className={"oser-top-content-container"}>
                    <div className={"oser-top-left-container"}>
                        <div className={"oser-top-left-decor"}/>
                        <div className={"oser-top-left-scrollbar"}>
                            <div className={"slider-wrapper"} ref={sliderWrapperRef}>
                                <HorizontalScrollBar thumbWidth={thumbWidth} scrollContainer={serviceList} divRef={sliderRef}/>
                            </div>
                        </div>
                        <div className={"oser-top-left-services-list"} ref={serviceList}>
                            {services.map((service, index) => {
                                const additionalClass = index === 0 ? "last-item" : index === services.length - 1 ? "first-item" : "";
                                return (
                                    <div key={index} className={"item " + additionalClass}>
                                        <img src={service.image} alt={"item-img"} className={"item-img"}/>
                                        <div className={"item-content"}>
                                            <div className={"item-detail"}>
                                                <div className={"item-title"}>
                                                    {service.name}
                                                </div>
                                                <div className={"item-description"}>
                                                    {service.price}
                                                </div>
                                            </div>
                                            <div className={"item-book-now"}>
                                                <div className={"item-bn-button"} onClick={() => {
                                                    openBookNowWeb()
                                                }}>
                                                    <img className={"item-bn-button-img"} src={BNButton} alt={"BNButton"}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={"oser-top-right-container"}>
                        <img src={OurServicesTitle} alt={"Our Services"} className={"oser-title"}/>
                        <div className={"oser-description"}>
                            {servicesConfig.description}
                        </div>
                        <img src={TwoStar} alt={"Two Star"} className={"oser-title-decor"}/>
                    </div>
                </div>
            </div>
            <WorkingHour/>
            <div className={"oser-mid-container"} id={"aboutUs"}>
                <div className={"oser-mid-left-container"}>
                    <div className={"oser-mid-decor"}>
                        <div className={"oser-mid-decor-inner"}>
                            <img className={"oser-mid-decor-inner-img"}
                                 src={servicesConfig.image1}
                                 alt={"img-bg"}/>
                        </div>
                    </div>
                    <div className={"h-decor-line first-line"}/>
                    <div className={"h-decor-line second-line"}/>
                </div>
                <div className={"oser-mid-right-container"}>
                    <img className={"oser-mid-right-img"} src={servicesConfig.image2} alt={"right-img"}/>
                    <div className={"oser-mid-right-text"}>
                        {servicesConfig.description1}
                        <div className={"oser-mid-right-view-more"} onClick={() => {
                            openFacebook()
                        }}>
                            VIEW MORE
                        </div>
                    </div>
                    <div className={"v-decor-line third-line"}/>
                </div>
            </div>
            <OurMission/>
        </div>
    );
}

export default OurServices;