import React, {useEffect} from 'react';
import './assets/styles/App.css';
import Background from "./components/Background";
import OurServices from "./parts/OurServices";
import OurStory from "./parts/OurStory";
import Contact from "./parts/Contact";
import AboutUs from "./parts/AboutUs";
import AppWelcome from "./parts/AppWelcome";

function setRootFontSize(size: number) {
    document.documentElement.style.fontSize = `${size}px`;
}

function App() {
    const recalculateRem = () => {
        const width = window.screen.width;
        setRootFontSize(width / 1920);
        const aspectRatio = window.screen.width / window.screen.height;
        if (aspectRatio > 1.5) {
            console.log(window.screen.width)
            console.log(width)
            console.log("Aspect ratio is greater than 1.5");
        } else {
            console.log(window.screen.width)
            console.log(width)
            console.log("Aspect ratio is less than 1.5");
        }
    }
    useEffect(() => {
        recalculateRem();
        window.addEventListener("resize", () => {
            recalculateRem();
        });
    });

    return (
        <Background>
            <AppWelcome/>
            <OurServices/>
            <AboutUs/>
            <OurStory/>
            <Contact/>
        </Background>
    );
}

export default App;
